<template>
 <el-submenu 
    id="menuLeft"
    v-if="menu.list && menu.list.length >= 1"
    :index="menu.name + ''"
    :popper-append-to-body="false"
    :popper-class="'site-sidebar--' + sidebarLayoutSkin + '-popper'">
    <template slot="title" >
      <!-- <div style="font-size:16px;display:inline-block" class="background" @click="gotoRouteHandle1(menu,indexx)">{{ menu.name }}1</div> -->
      <div style="font-size:16px;display:inline-block" class="background" >{{ menu.name }}</div>
    </template>

    <sub-menu
      background-color="#545c64"
      v-for="item in menu.list" 
      :key="item.id"
      :menu="item"
      :dynamicMenuRoutes="dynamicMenuRoutes">
    </sub-menu>
  </el-submenu>
  <!-- 只有一层 -->
  <el-menu-item v-else :index="menu.name + ''" @click="gotoRouteHandle(menu,indexx)" id="menuLeft">
      <span style="font-size:16px">{{ menu.name }}</span>
  </el-menu-item>
</template>

<script>
 export default {
    name: 'sub-menu',
    // props: {
    //    indexx: {
    //     type: Object,
    //     required: true
    //   },
    //   menu: {
    //     type: Object,
    //     required: true
    //   },
    //   dynamicMenuRoutes: {
    //     type: Array,
    //     required: true
    //   }
    // },
    props:["menu","dynamicMenuRoutes","indexx"],
    components: {},
    computed: {
      sidebarLayoutSkin: {
        get () { return this.$store.state.common.sidebarLayoutSkin }
      }
    },
    methods: {
      // 通过menuId与动态(菜单)路由进行匹配跳转至指定路由
      gotoRouteHandle (menu,a) {
       var route = this.dynamicMenuRoutes.filter(item => item.meta.id === menu.id)
        if (route.length >= 1) {
          console.log(menu,"pe");
          console.log(a,"d");
           this.$emit("changeindex",a)
          this.$router.push({ name: route[0].name })
        }
      },
      // 二级菜单点击页面
       gotoRouteHandle1 (menu,a) {
       this.$emit("changeindex",a)
        this.$router.push(menu.url)
       }
    }
  }
</script>
<style scoped lang="scss">
.el-menu--horizontal>.el-menu-item {
  height: 80px;
  line-height: 80px;
}
 /deep/.el-submenu__title {
  height: 80px  !important;
  line-height: 80px !important;
}
.background{
  // background: pink;
}

// /deep/.el-submenu__title.is-active { color: #ffff !important; background-color: #8bc590!important; }
// /deep/.el-menu-item.is-active { color: #ffff !important; background-color: #8bc590!important; }
</style>