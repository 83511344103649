<template>
  <aside class="site-sidebar box" :class="'site-sidebar--' + sidebarLayoutSkin">
    <div  class="companyname ">
      <p class="text animate" v-if="partyName.length>16"> {{partyName}}</p>
      <p v-else > {{partyName}}</p>
     </div>
    <div class="site-sidebar__inner" style="display: inline-block;">
       <!-- active-text-color="#ffd04b" -->
       <!-- background-color="#9e99b9" -->
        <!-- :default-active="menuActiveName || 'home'" -->
      <el-menu
        background-color="#9e99b9"
        text-color="#fff"
        active-text-color="#63D1C4"
        style="height:60px;font-size:22px;margin-left:20px"
        mode="horizontal"
        :default-active="activeMenu" 
        :collapse="sidebarFold"
        :collapseTransition="false"
        class="site-sidebar__menu menusty">
        <sub-menu
          v-for="(menu,index) in menuList"
          :class="{'is-active':index==chooseindex}"
          :key="menu.menuId"
          :menu="menu"
          :indexx="index"
          @changeindex="getindex"
          :dynamicMenuRoutes="dynamicMenuRoutes">
        </sub-menu>
      </el-menu>
    </div>
  </aside>
</template>

<script>
  import SubMenu from './main-sidebar-sub-menu'
  import { isURL } from '/src/utils/util'
  export default {
    data () {
      return {
        dynamicMenuRoutes: [],
        partyName:"",
        chooseindex:""
        
      }
    },
    components: {
      SubMenu
    },
    computed: {
      sidebarLayoutSkin: {
        get () { return this.$store.state.common.sidebarLayoutSkin }
      },
      sidebarFold: {
        get () { return this.$store.state.common.sidebarFold }
      },
      menuList: {
        get () { return this.$store.state.common.menuList },
        set (val) { this.$store.commit('common/updateMenuList', val) }
      },
      menuActiveName: {
        get () { return this.$store.state.common.menuActiveName },
        set (val) { this.$store.commit('common/updateMenuActiveName', val) }
      },
      mainTabs: {
        get () { return this.$store.state.common.mainTabs },
        set (val) { this.$store.commit('common/updateMainTabs', val) }
      },
      mainTabsActiveName: {
        get () { return this.$store.state.common.mainTabsActiveName },
        set (val) { this.$store.commit('common/updateMainTabsActiveName', val) }
      },
      activeMenu() {return this.$route.meta.name}

    },
    watch: {
      $route: 'routeHandle'
    },
    activated(){
     
    },
    created () {
      this.partyName=localStorage.getItem('partyName')
      this.menuList = JSON.parse(sessionStorage.getItem('menuList') || '[]')
      this.dynamicMenuRoutes = JSON.parse(sessionStorage.getItem('dynamicMenuRoutes') || '[]')
      // this.routeHandle(this.$route)
    },
    methods: {
      getindex(a){
        this.chooseindex=a
        // console.log(a,"eewe");
      },
      // 路由操作
      routeHandle (route) {
        if (route.meta.isTab) {
          // tab选中, 不存在先添加
          var tab = this.mainTabs.filter(item => item.name === route.name)[0]
          if (!tab) {
            if (route.meta.isDynamic) {
              route = this.dynamicMenuRoutes.filter(item => item.name === route.name)[0]
             if (!route) {
                return console.error('未能找到可用标签页!')
              }
            }
            tab = {
              menuId: route.meta.menuId || route.name,
              name: route.name,
              title: route.meta.title,
              type: isURL(route.meta.iframeUrl) ? 'iframe' : 'module',
              iframeUrl: route.meta.iframeUrl || '',
              params: route.params,
              query: route.query
            }
            this.mainTabs = this.mainTabs.concat(tab)
          }
          this.menuActiveName = tab.menuId + ''
          this.mainTabsActiveName = tab.name
        }
      }
    }
  }
</script>
<style scoped lang="scss">
.companyname{
  overflow: hidden;
  max-width:368px;
  display: inline-block;
  color:#ffff;
  height: 80px;
  line-height:40px;

  font-size: 20px;
  font-weight: 600;
  text-indent:24px;

}
.menusty{
  font-size: 20px!important;
}
.site-sidebar__inner{
  //  background: darkcyan !important;
}
.box {
            /* width: 200px; */
            margin: 0 auto;
            position: relative;
            /* border: 1px solid #ff6700; */
        }
 
        .animate {
            // padding-left: 20px;
            white-space: nowrap;
            display: inline-block;
            animation: wordsLoop 10s  linear infinite normal;
        }
 
        @keyframes wordsLoop {
            0%{
                transform: translateX(0px);
                -webkit-transform: translateX(0px);
            }
             30%{
                transform: translateX(0px);
                -webkit-transform: translateX(0px);
            }
            100%{
                transform: translateX(-100%);
                -webkit-transform: translateX(-100%);
            }
        }
 
        @-webkit-keyframes wordsLoop {
            0% {
                transform: translateX(0px);
                -webkit-transform: translateX(0px);
            }
             30%{
                transform: translateX(0px);
                -webkit-transform: translateX(0px);
            }
            100% {
                transform: translateX(-100%);
                -webkit-transform: translateX(-100%);
            }
}
// .el-menu--horizontal > .el-submenu .el-submenu__title{
//     background-color: #fff;
// }
.site-sidebar__inner /deep/ .baer{
  background: darkcyan !important;
}
// /deep/.el-submenu__title.is-active { color: #ffff !important; background-color: blue!important; }
// /deep/.el-menu-item.is-active { color: #ffff !important; background-color: blue!important; }
// /deep/.el-submenu.is-active { color: red !important; background-color: darkcyan!important; }
// /deep/.el-submenu.is-opened { color: #ffff !important; background-color: darkcyan!important; }
</style>
