<template>
  <div class="mod-home bottom">
      <div class="box13">
        <div>
           <div class="box" ><img src="~pub/img/logo1.jpg" alt=""></div>
           <div  class="box1" style="margin-left:10px">
              <p>官方微信公众号</p>
              <p>公众号名称 雲途志</p>
              <p>服务时间 工作日9:00~18:00</p>
          </div>
        </div>
         <div class="bottomm">业务合作联系电话：13261608883</div>
       
       
      </div>
      <div class="box13">
        <div>
           <div class="box" ><img src="~pub/img/xcx.jpg" alt=""></div>
           <div  class="box1" style="margin-left:10px">
              <p>官方小程序</p>
              <p>小程序名称    雲途志</p>
             <p>服务时间 0:00~24:00</p>
          </div>
        </div>
        <div class="bottomm">联系邮箱：hejiawei@hunter job.net</div>
       
      </div>
      <div class="box13">
        <div style="background:pink">
           <img src="~pub/img/hjq.png" alt="" style="float:left"> 
           <!-- <div class="box"><img src="~pub/img/hjq.png" alt="" style="float:left"></div> -->
           <div  class="box1" style="margin-left:10px">
              <p>专属商务企业微信</p>
              <p>商务姓名    何佳伟</p>
              <p>服务时间 工作日9:00~18:00</p>
          </div>
       </div>
        <div class="bottomm">ICP备案：辽ICP备2021002787号-1</div>
      </div>
  </div>
</template>

<script>
  export default {
  }
</script>

<style scoped>
  .bottom{
      color:#ffff;
      width: 100%;
      height: 300px;
      background: #444444;
      font-size: 14px;
       display: flex;
      align-items: center;
      justify-content: space-evenly;
      flex-wrap: wrap;
  }
  .box{
   float: left;
}
  .box1{
    line-height: 30px;
    height: 150px;
    float: left;
}
  img{
    width: 150px;
     float: left;
}
.bottomm{
  float: left;
  width: 100%;
  text-align: center;
  transform: translateY(20px);
}
.aav{
  float: left;
  height: 20px;
  background: darkgray;
}
</style>

