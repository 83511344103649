<template>
  <nav class="site-navbar navclass"  >
    <!-- 消息中心 -->
    <div class="btn-bell" style="float:left">
      <el-tooltip
        effect="dark"
        :content="message ? `有${message}条未读消息` : `消息中心`"
        placement="bottom"
      >
      <i class="el-icon-bell" style="color:#ffff;font-size:26px"></i>
        <!-- <router-link to="/tabs">
          <i class="el-icon-bell" style="color:#ffff;font-size:26px"></i>
        </router-link> -->
      </el-tooltip>
      <span class="btn-bell-badge" v-if="message"></span>
    </div>
    <!-- 用户名 -->
    <el-dropdown class="user-name" trigger="click" style="float:left;">
      <span class="el-dropdown-link" style="margin-left:10px;color:#ffff">
        {{username}}
        <i class="el-icon-caret-bottom"></i>
      </span>
      <template #dropdown>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item @click.native="updatePasswordHandle()"
            >修改密码</el-dropdown-item
          >
          <el-dropdown-item @click.native="logoutHandle()"
            >退出</el-dropdown-item
          >
        </el-dropdown-menu>
      </template>
    </el-dropdown>
  </nav>
</template>

<script>
import { clearLoginInfo } from "@/utils";
import {getUsername} from "@/utils/util"
import { logout,info} from "@/api/index";
export default {
  data() {
    return {
      message: 2,
      username:""
    };
  },
  components: {
  
  },
  computed: {
  },
  created(){
    // this.username=getUsername()
  },
  mounted(){
    this.getUserInfo()
  },
  methods: {
    // 修改密码
    updatePasswordHandle() {
     this.$router.push("changePassword");
    },
    // 退出
    logoutHandle() {
      this.$confirm(`确定进行[退出]操作?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          logout({}).then((data)=>{
           if (data && data.code === 200) {
              clearLoginInfo();
              this.$router.push({ name: "login" });
            }
          })})
        .catch(() => {});
    },
     // 获取当前管理员信息
      getUserInfo () {
        info({}).then((data) => {
          // console.log(data);
          if (data && data.code ==200) {
            this.username = data.data.alisa
          }
        })
      }
  },
};
</script>
<style scoped>

.btn-bell,
.btn-fullscreen {
    position: relative;
    width: 30px;
    height: 30px;
    text-align: center;
    border-radius: 15px;
    cursor: pointer;
}
.user-name{
   cursor: pointer;
}
.btn-bell-badge {
    position: absolute;
    right: 0;
    top: -2px;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background: #f56c6c;
    color: #fff;
}
.btn-bell .el-icon-bell {
    color: #fff;
}
.navclass{
   line-height: 50px;
   transform: translateY(-12px);
}
</style>
