<template>
  <div
    class="site-wrapper"
   :class="{ 'site-sidebar--fold': sidebarFold }"
    v-loading.fullscreen.lock="loading"
    element-loading-text="拼命加载中">
    <template v-if="!loading">
      <div  class="top">
         <main-sidebar style="display:inline-block;height:80px;"/>
         <main-navbar style="float: right;  transform: translate(-30px,16px);"/>
      </div>
      <!-- <div class="site-content__wrapper main" :style="{ 'min-height': documentClientHeight + 'px' }"> -->
      <div  class="main">
        <main-content v-if="!$store.state.common.contentIsNeedRefresh" />
      </div>
      <div class="bottom">
        <main-bottom></main-bottom>
      </div>
    </template>
    
  </div>
  
</template>

<script>
  import MainNavbar from './main-navbar'
  import MainSidebar from './main-sidebar'
  import MainContent from './main-content'
  import MainBottom from './main-bottom'
  import { info } from "@/api/index";
  export default {
    provide () {
      return {
        // 刷新
        refresh () {
          this.$store.commit('common/updateContentIsNeedRefresh', true)
          this.$nextTick(() => {
            this.$store.commit('common/updateContentIsNeedRefresh', false)
          })
        }
      }
    },
    data () {
      return {
        loading: false
      }
    },
    components: {
      MainNavbar,
      MainSidebar,
      MainContent,
      MainBottom
    },
    computed: {
      documentClientHeight: {
        get () { return this.$store.state.common.documentClientHeight },
        set (val) { this.$store.commit('common/updateDocumentClientHeight', val) }
      },
      sidebarFold: {
        get () { return this.$store.state.common.sidebarFold }
      },
      userId: {
        get () { return this.$store.state.user.id },
        set (val) { this.$store.commit('user/updateId', val) }
      },
      userName: {
        get () { return this.$store.state.user.name },
        set (val) { this.$store.commit('user/updateName', val) }
      }
    },
    created () {
      // this.getUserInfo()
    },
    mounted () {
      this.resetDocumentClientHeight()
    },
    methods: {
      // 重置窗口可视高度
      resetDocumentClientHeight () {
        this.documentClientHeight = document.documentElement['clientHeight']
        window.onresize = () => {
          this.documentClientHeight = document.documentElement['clientHeight']
        }
      },
      // 获取当前管理员信息
      getUserInfo () {
        info({}).then((data) => {
          if (data && data.code == 0) {
            this.loading = false
            this.userId = data.user.userId
            this.userName = data.user.username
          }
        })
      }
    }
  }
</script>
<style scoped>
.top{
  background:#9e99b9;
  width:100%;
  position: fixed;
  top: 0px;
  left:0px;
  z-index: 100;
  height: 80px;
}
.main{
   margin-top:80px;
   width: 100%;
}
</style>
